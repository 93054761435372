import Icon from "@components/Icon";
import React, {useRef} from "react";

import {useDisclosure} from "../../../../hooks/useDisclosure";
import {useOnClickOutside} from "../../../../hooks/useOnClickOutside";
import {useKeyHandler} from "../../../../useEscape";
import {useArrowKeyFocusScope} from "../../../../utils/a11y/useArrowKeyFocus";
import {ButtonVariant} from "./types";
import getMapOptionStyle from "@components/components/Tree/getMapOptionStyle";

const CONTAINER_WIDTH = 350;
const variantProps = {
  primary: {
    className: "brdn br5 fw6 p3 df aic bg-transparent cp aria-focus contrast-tb",
    text: "label-lg fs18 fs14-sm lh26 mr2 w-full",
    style: {},
  },
  secondary: {
    className: "brdn br2 fw4 p4 df aic cp aria-focus bg-gray100 jcsb aibl contrast-tb",
    text: "label-lg fs18 fs14-sm lh26 mr2 w-full",
    style: {width: CONTAINER_WIDTH},
  },
  locations: {
    className: `${getMapOptionStyle(true)} w-full justify-between`,
    text: "text-basic leading-loose",
    style: {maxWidth: CONTAINER_WIDTH},
  },
};

interface TreeOverlayProps {
  selectedItem: string;
  selectorName: string;
  renderChildren: (close: () => void) => React.ReactElement;
  onClose: () => unknown;
  variant: ButtonVariant;
}

export const TreeOverlay: React.FC<TreeOverlayProps> = ({
  onClose,
  selectorName,
  renderChildren,
  selectedItem,
  variant,
}) => {
  const disclosure = useDisclosure();
  const ref = useRef<HTMLDivElement | null>(null);

  const handleClose = () => {
    onClose();
    disclosure.close();
  };

  useOnClickOutside(ref, handleClose);
  useKeyHandler("Escape", handleClose);

  const {setFocusScope} = useArrowKeyFocusScope(disclosure.isOpen);

  return (
    <div ref={ref} className="relative inline-block zIndex2 flex-grow" style={{maxWidth: 350}}>
      <div id="react-aria-select-descriptor" className="visually-hidden">
        {selectorName}
      </div>
      <div>
        <button
          id="tree-popup-trigger"
          onClick={disclosure.toggle}
          aria-labelledby="react-aria-select-descriptor react-aria-selected-item"
          style={variantProps[variant].style}
          className={
            variantProps[variant].className ||
            "brdn br5 fw6 p3 df aibl bg-transparent cp aria-focus"
          }
          data-cy="comboboxButton"
          aria-expanded={disclosure.isOpen}
          aria-controls="tree-popup"
          aria-haspopup="tree"
        >
          <span id="react-aria-selected-item" className={variantProps[variant].text}>
            {selectedItem}
          </span>
          <Icon icon="dropdown-arrow-down" className="text-gray-700" />
        </button>
      </div>
      <div
        id="tree-popup"
        ref={setFocusScope}
        aria-modal
        className={`mt2 pos-a zIndex1 ${disclosure.isOpen ? "" : "opacity-0 invisible"}`}
        style={{width: CONTAINER_WIDTH}}
      >
        {renderChildren(handleClose)}
      </div>
    </div>
  );
};
export default React.memo(TreeOverlay);
